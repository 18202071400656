/**
 * 暗色模式下的代码高亮
 */
.mdui-theme-layout-dark .hljs {
  display: block;
  overflow-x: auto;
  padding: .5em;
  background: #303030;
  /*background:#1e1e1e;*/
  color: #dcdcdc
}

.mdui-theme-layout-dark .hljs-keyword,
.mdui-theme-layout-dark .hljs-literal,
.mdui-theme-layout-dark .hljs-name,
.mdui-theme-layout-dark .hljs-symbol {
  color: #569cd6
}

.mdui-theme-layout-dark .hljs-link {
  color: #569cd6;
  text-decoration: underline
}

.mdui-theme-layout-dark .hljs-built_in,
.mdui-theme-layout-dark .hljs-type {
  color: #4ec9b0
}

.mdui-theme-layout-dark .hljs-class,
.mdui-theme-layout-dark .hljs-number {
  color: #b8d7a3
}

.mdui-theme-layout-dark .hljs-meta-string,
.mdui-theme-layout-dark .hljs-string {
  color: #d69d85
}

.mdui-theme-layout-dark .hljs-regexp,
.mdui-theme-layout-dark .hljs-template-tag {
  color: #9a5334
}

.mdui-theme-layout-dark .hljs-formula,
.mdui-theme-layout-dark .hljs-function,
.mdui-theme-layout-dark .hljs-params,
.mdui-theme-layout-dark .hljs-subst,
.mdui-theme-layout-dark .hljs-title {
  color: #dcdcdc
}

.mdui-theme-layout-dark .hljs-comment,
.mdui-theme-layout-dark .hljs-quote {
  color: #57a64a;
  font-style: italic
}

.mdui-theme-layout-dark .hljs-doctag {
  color: #608b4e
}

.mdui-theme-layout-dark .hljs-meta,
.mdui-theme-layout-dark .hljs-meta-keyword,
.mdui-theme-layout-dark .hljs-tag {
  color: #9b9b9b
}

.mdui-theme-layout-dark .hljs-template-variable,
.mdui-theme-layout-dark .hljs-variable {
  color: #bd63c5
}

.mdui-theme-layout-dark .hljs-attr,
.mdui-theme-layout-dark .hljs-attribute,
.mdui-theme-layout-dark .hljs-builtin-name {
  color: #9cdcfe
}

.mdui-theme-layout-dark .hljs-section {
  color: gold
}

.mdui-theme-layout-dark .hljs-emphasis {
  font-style: italic
}

.mdui-theme-layout-dark .hljs-strong {
  font-weight: 700
}

.mdui-theme-layout-dark .hljs-bullet,
.mdui-theme-layout-dark .hljs-selector-attr,
.mdui-theme-layout-dark .hljs-selector-class,
.mdui-theme-layout-dark .hljs-selector-id,
.mdui-theme-layout-dark .hljs-selector-pseudo,
.mdui-theme-layout-dark .hljs-selector-tag {
  color: #d7ba7d
}

.mdui-theme-layout-dark .hljs-addition {
  background-color: #144212;
  display: inline-block;
  width: 100%
}

.mdui-theme-layout-dark .hljs-deletion {
  background-color: #600;
  display: inline-block;
  width: 100%
}
