/**
 * =============================================================================
 * ************   Dialog confirm   ************
 * =============================================================================
 */

@import '../textfield.less';
@import './index.less';

.mdui-dialog-prompt {
  max-width: 448px;

  .mdui-dialog-content {
    padding-bottom: 16px;
  }

  .mdui-textfield {
    padding-top: 0;
  }
}
